import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { logger } from '../utils/logger';
import LockedContent from './LockedContent';

interface MembershipProtectedRouteProps {
  children: React.ReactNode;
}

const MembershipProtectedRoute: React.FC<MembershipProtectedRouteProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isActiveMember, setIsActiveMember] = useState(false);

  useEffect(() => {
    const checkMembershipStatus = async () => {
      setIsLoading(true);
      try {
        const user = auth.currentUser;
        if (!user || !user.uid) {
          setIsActiveMember(false);
          setIsLoading(false);
          logger.warn('Please login to continue', null, { showToast: true });
          return;
        }

        const userRef = doc(db, 'userDetails', user.uid);
        if (!userRef) {
          setIsActiveMember(false);
          setIsLoading(false);
          logger.warn('Invalid user reference', null, { showToast: true });
          return;
        }

        const userDoc = await getDoc(userRef);
        if (!userDoc.exists()) {
          setIsActiveMember(false);
          setIsLoading(false);
          logger.warn('Member details not found', null, { showToast: true });
          return;
        }

        const userData = userDoc.data();
        if (!userData) {
          setIsActiveMember(false);
          setIsLoading(false);
          logger.warn('Member data is empty', null, { showToast: true });
          return;
        }

        const isActive = userData.memberStatus === 'Active';
        setIsActiveMember(isActive);
        if (!isActive) {
          logger.warn('Member status is not active', null, { showToast: true });
        }
      } catch (error) {
        setIsActiveMember(false);
        logger.error('Error checking membership status', null, { showToast: true });
      } finally {
        setIsLoading(false);
      }
    };

    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        checkMembershipStatus();
      } else {
        setIsActiveMember(false);
        setIsLoading(false);
        logger.warn('Please login to continue', null, { showToast: true });
      }
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center bg-gray-900/5 rounded-xl">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!auth.currentUser) {
    return <Navigate to="/login" replace />;
  }

  // Show the actual content with the lock overlay if not an active member
  return (
    <div className="relative">
      {children}
      {!isActiveMember && (
        <div className="absolute inset-0">
          <LockedContent />
        </div>
      )}
    </div>
  );
};

export default MembershipProtectedRoute;
