import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { collection, query, orderBy, Timestamp, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../../../config/firebase';
import { FaArrowLeft, FaClock, FaUser, FaTags, FaEdit, FaGlobe, FaUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { logger } from '../../../utils/logger';

interface Article {
  id: string;
  title: string;
  heading: string;
  subHeading: string;
  content: string;
  authorId: string;
  authorName?: string;
  createdAt: any;
  tags: string[];
  status: string;
  visibility: string;
  featuredImage?: string;
}

const Articles: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const [authorDetailsCache, setAuthorDetailsCache] = useState<{[key: string]: any}>({});
  const navigate = useNavigate();

  const fetchAuthorDetails = useCallback(async (userId: string) => {
    const user = auth.currentUser;
    if (!user) {
      logger.warn('Please login to continue', null, { showToast: true });
      navigate('/login');
      return null;
    }

    if (!userId || typeof userId !== 'string') {
      logger.warn('Invalid author ID format', null, { showToast: true });
      return null;
    }

    // Check cache first
    if (authorDetailsCache[userId]) {
      return authorDetailsCache[userId];
    }

    try {
      const userRef = doc(db, 'userDetails', userId);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        // Update cache
        setAuthorDetailsCache(prev => ({
          ...prev,
          [userId]: userData
        }));
        return userData;
      }
      return null;
    } catch (error) {
      logger.error('Error fetching author details', null, { showToast: true });
      return null;
    }
  }, [authorDetailsCache, navigate]);

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      logger.warn('Please login to continue', null, { showToast: true });
      navigate('/login');
      return;
    }

    let unsubscribe: () => void;

    try {
      const q = query(
        collection(db, 'articles'),
        orderBy('createdAt', 'desc')
      );

      unsubscribe = onSnapshot(q, {
        next: async (snapshot) => {
          try {
            const articlesData = await Promise.all(
              snapshot.docs.map(async (doc) => {
                const data = doc.data() as Omit<Article, 'id'>;
                if (!data.authorId) {
                  logger.warn(`Article ${doc.id} has no author ID`, null, { showToast: false });
                  return {
                    id: doc.id,
                    ...data,
                    authorName: 'Unknown Author',
                    createdAt: data.createdAt || Timestamp.now()
                  } as Article;
                }

                const authorDetails = await fetchAuthorDetails(data.authorId);
                return {
                  id: doc.id,
                  ...data,
                  authorName: authorDetails?.fullName || authorDetails?.email || 'Unknown Author',
                  createdAt: data.createdAt || Timestamp.now()
                } as Article;
              })
            );

            setArticles(articlesData.filter(article => article !== null));
            setLoading(false);
          } catch (error) {
            logger.error('Error loading articles', null, { showToast: true });
            setLoading(false);
          }
        },
        error: (error) => {
          logger.error('Error loading articles', null, { showToast: true });
          setLoading(false);
        }
      });

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    } catch (error) {
      logger.error('Error loading articles', null, { showToast: true });
      setLoading(false);
      return () => {};
    }
  }, [navigate, fetchAuthorDetails]);

  const handleArticleClick = useCallback((article: Article) => {
    try {
      setSelectedArticle(article);
    } catch (error) {
      logger.error('Error selecting article', null, { showToast: true });
    }
  }, []);

  const handleEditArticle = useCallback((articleId: string) => {
    try {
      navigate(`/dashboard/articles/edit/${articleId}`);
    } catch (error) {
      logger.error('Error editing article', null, { showToast: true });
    }
  }, [navigate]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-emerald-500"></div>
      </div>
    );
  }

  if (selectedArticle) {
    return (
      <FullArticleView
        article={selectedArticle}
        onBack={() => setSelectedArticle(null)}
      />
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 py-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        {/* Header */}
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center gap-4">
            <div className="px-4 py-2 bg-slate-700/50 rounded-lg">
              <span className="text-emerald-400 font-semibold">{articles.length}</span>
              <span className="text-slate-400 ml-2">Articles</span>
            </div>
            {articles.length > 0 && (
              <div className="px-4 py-2 bg-slate-700/50 rounded-lg">
                <span className="text-slate-400">Latest: </span>
                <span className="text-white font-medium">{articles[0].heading}</span>
              </div>
            )}
          </div>

          {/* Filter Toggle */}
          <div className="flex items-center gap-2 bg-slate-800 rounded-lg p-1">
            <button
              onClick={() => console.log('All Articles')}
              className="flex items-center gap-2 px-4 py-2 rounded-md transition-colors text-slate-400 hover:text-white"
            >
              <FaGlobe className="w-4 h-4" />
              All Articles
            </button>
            <button
              onClick={() => console.log('My Articles')}
              className="flex items-center gap-2 px-4 py-2 rounded-md transition-colors text-slate-400 hover:text-white"
            >
              <FaUserCircle className="w-4 h-4" />
              My Articles
            </button>
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
        >
          {articles.map((article, index) => (
            <motion.div
              key={article.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              whileHover={{ y: -5, scale: 1.02 }}
              className={`bg-slate-800 rounded-lg overflow-hidden cursor-pointer transform transition-all duration-300 ${
                index === 0 ? 'md:col-span-2 lg:col-span-3' : ''
              }`}
              onClick={() => handleArticleClick(article)}
            >
              {index === 0 && (
                <div className="absolute top-4 left-4 z-10">
                  <span className="px-3 py-1 bg-primary text-white text-xs font-medium rounded-full">
                    Latest Article
                  </span>
                </div>
              )}
              
              {/* Article Image */}
              {article.featuredImage && (
                <div className={`relative ${index === 0 ? 'h-64' : 'h-48'} overflow-hidden`}>
                  <img
                    src={article.featuredImage}
                    alt={article.heading}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-slate-900/90 to-transparent" />
                </div>
              )}

              {/* Article Content */}
              <div className="p-6">
                <h2 className={`${index === 0 ? 'text-2xl' : 'text-xl'} font-semibold text-white mb-2 line-clamp-2`}>
                  {article.heading}
                </h2>
                <h3 className="text-slate-400 text-sm mb-3 line-clamp-2">
                  {article.subHeading}
                </h3>

                <div className="flex items-center gap-4 text-sm text-slate-400 mb-4">
                  <div className="flex items-center gap-2">
                    <FaUser className="w-4 h-4" />
                    <span>{article.authorName}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <FaClock className="w-4 h-4" />
                    <span>{article.createdAt.toDate().toLocaleDateString()}</span>
                  </div>
                </div>

                <div
                  className="text-slate-400 line-clamp-3 mb-4"
                  dangerouslySetInnerHTML={{
                    __html: article.content.replace(/<[^>]*>/g, '')
                  }}
                />

                <div className="flex items-center justify-between">
                  {auth.currentUser && article.authorId === auth.currentUser.uid && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditArticle(article.id);
                      }}
                      className="inline-flex items-center px-3 py-1 bg-emerald-500/20 text-emerald-400 rounded-full text-xs hover:bg-emerald-500/30 transition-colors"
                    >
                      <FaEdit className="w-3 h-3 mr-1" />
                      Edit Article
                    </button>
                  )}
                  {article.tags && article.tags.length > 0 && (
                    <div className="flex gap-2">
                      {article.tags.slice(0, 2).map((tag, index) => (
                        <span
                          key={index}
                          className="px-2 py-1 bg-slate-700/50 rounded-full text-xs"
                        >
                          {tag}
                        </span>
                      ))}
                      {article.tags.length > 2 && (
                        <span className="px-2 py-1 bg-slate-700/50 rounded-full text-xs">
                          +{article.tags.length - 2}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>

        {/* Empty State */}
        {articles.length === 0 && (
          <div className="text-center py-12 bg-slate-800/50 rounded-xl backdrop-blur-sm">
            <div className="text-6xl mb-4">📚</div>
            <p className="text-slate-400 text-lg">No articles published yet</p>
          </div>
        )}
      </motion.div>
    </div>
  );
};

const FullArticleView: React.FC<{
  article: Article;
  onBack: () => void;
}> = ({ article, onBack }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="min-h-screen bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800 text-slate-300 p-6"
    >
      <div className="max-w-4xl mx-auto">
        {/* Back Button */}
        <button
          onClick={onBack}
          className="mb-6 px-4 py-2 flex items-center gap-2 text-slate-400 hover:text-white transition-colors"
        >
          <FaArrowLeft className="w-4 h-4" />
          <span>Back to Articles</span>
        </button>

        {/* Article Header */}
        <div className="bg-slate-800/50 rounded-xl p-8 backdrop-blur-sm mb-6">
          <h1 className="text-4xl font-bold text-white mb-2">
            {article.heading}
          </h1>
          <h2 className="text-xl text-slate-400 mb-4">
            {article.subHeading}
          </h2>
          
          <div className="flex flex-wrap items-center gap-6 text-sm text-slate-400">
            <div className="flex items-center gap-2">
              <FaUser className="w-4 h-4" />
              <span>{article.authorName}</span>
            </div>
            <div className="flex items-center gap-2">
              <FaClock className="w-4 h-4" />
              <span>Published {article.createdAt.toDate().toLocaleDateString()}</span>
            </div>
            {article.tags && article.tags.length > 0 && (
              <div className="flex items-center gap-2">
                <FaTags className="w-4 h-4" />
                <div className="flex gap-2">
                  {article.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 bg-slate-700/50 rounded-full text-xs"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Featured Image */}
        {article.featuredImage && (
          <div className="mb-8 rounded-xl overflow-hidden">
            <img
              src={article.featuredImage}
              alt={article.heading}
              className="w-full h-[400px] object-cover"
            />
          </div>
        )}

        {/* Article Content */}
        <div className="bg-slate-800/50 rounded-xl p-8 backdrop-blur-sm">
          <div 
            className="prose prose-invert max-w-none"
            dangerouslySetInnerHTML={{ __html: article.content }}
          />
        </div>

        {/* Article Footer */}
        <div className="mt-8 p-6 bg-slate-800/50 rounded-xl backdrop-blur-sm">
          <div className="flex items-center justify-between text-sm text-slate-400">
            <div className="flex items-center gap-2">
              <FaClock className="w-4 h-4" />
              <span>Last updated {article.createdAt.toDate().toLocaleDateString()}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="px-3 py-1 bg-slate-700/50 rounded-full">
                {article.status}
              </span>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Articles;
