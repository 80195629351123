import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { db, storage, auth } from '../../../config/firebase';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { logger } from '../../../utils/logger';

interface UserDetails {
  fullName: string;
  email: string;
  photo: string;
}

const NewArticle = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    
    const fetchUserDetails = async () => {
      const user = auth.currentUser;
      if (!user) {
        logger.error('Please login to create an article', null, { showToast: true });
        navigate('/login');
        return;
      }

      try {
        // First try userDetails collection
        const userDetailsRef = doc(db, 'userDetails', user.uid);
        const userDetailsDoc = await getDoc(userDetailsRef);

        if (userDetailsDoc.exists()) {
          const details = userDetailsDoc.data() as UserDetails;
          setUserDetails(details);
          return;
        }

        // If not found, try users collection
        const usersRef = doc(db, 'users', user.uid);
        const usersDoc = await getDoc(usersRef);

        if (usersDoc.exists()) {
          const userData = usersDoc.data() as UserDetails;
          setUserDetails(userData);
        }
      } catch (error) {
        logger.error('Error fetching user details', error, { showToast: true });
      }
    };

    fetchUserDetails();
  }, [navigate]);

  const [formData, setFormData] = useState({
    heading: '',
    subHeading: '',
    content: '',
    featuredImage: null as File | null,
  });

  const quillRef = useRef<ReactQuill>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleEditorChange = (content: string) => {
    setFormData(prev => ({ ...prev, content }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormData(prev => ({ ...prev, featuredImage: e.target.files![0] }));
      setUploadProgress(0); // Reset progress when new file is selected
    }
  };

  const uploadImage = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `articles/${Date.now()}-${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          logger.error('Upload error', error, { showToast: true });
          reject(error);
        },
        async () => {
          try {
            const url = await getDownloadURL(storageRef);
            resolve(url);
          } catch (error) {
            logger.error('Error getting download URL', error, { showToast: true });
          }
        }
      );
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setUploadProgress(0);

    const user = auth.currentUser;
    if (!user) {
      logger.error('Please login to create an article', null, { showToast: true });
      navigate('/login');
      return;
    }

    try {
      let imageUrl = '';
      if (formData.featuredImage) {
        imageUrl = await uploadImage(formData.featuredImage);
      }

      const articleData = {
        heading: formData.heading,
        subHeading: formData.subHeading,
        content: formData.content,
        featuredImage: imageUrl,
        authorId: user.uid,
        authorEmail: user.email,
        authorName: userDetails?.fullName || user.displayName || user.email || 'Unknown Author',
        authorPhoto: userDetails?.photo || user.photoURL || '',
        category: 'general',
        tags: [],
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      await addDoc(collection(db, 'articles'), articleData);
      logger.info('Article created successfully', null, { showToast: true });
      navigate('/dashboard');
    } catch (error) {
      logger.error('Error creating article', error, { showToast: true });
    } finally {
      setIsLoading(false);
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    }
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'link'
  ];

  const inputClassName = `
    w-full px-4 py-3 
    bg-slate-800/40 
    border border-slate-700/50 
    rounded-lg 
    focus:ring-2 focus:ring-emerald-500/50 focus:border-emerald-500/50 
    transition-all duration-200
    text-slate-200
    [-webkit-text-fill-color:rgb(226,232,240)]
    [&::placeholder]:[-webkit-text-fill-color:rgb(100,116,139)]
    [&::-webkit-input-placeholder]:[-webkit-text-fill-color:rgb(100,116,139)]
    [&:-webkit-autofill]:[background-color:transparent]
    [&:-webkit-autofill]:[box-shadow:0_0_0_1000px_transparent_inset]
    [&:-webkit-autofill]:[-webkit-text-fill-color:rgb(226,232,240)]
    [&:-webkit-autofill]:[transition:background-color_5000s_ease-in-out_0s]
  `;

  const labelClassName = "block text-sm font-medium text-slate-300 mb-1";

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800 text-slate-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-3xl font-bold text-emerald-400">Create New Article</h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className={labelClassName}>
              Heading
            </label>
            <input
              type="text"
              name="heading"
              value={formData.heading}
              onChange={handleInputChange}
              required
              className={inputClassName}
              placeholder="Enter article heading"
            />
          </div>

          <div>
            <label className={labelClassName}>
              Sub Heading
            </label>
            <input
              type="text"
              name="subHeading"
              value={formData.subHeading}
              onChange={handleInputChange}
              required
              className={inputClassName}
              placeholder="Enter article sub heading"
            />
          </div>

          <div>
            <label className={labelClassName}>
              Content
            </label>
            <div className="prose prose-invert max-w-full">
              <ReactQuill
                ref={quillRef}
                value={formData.content}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats}
                theme="snow"
                className="bg-slate-800/40 rounded-lg text-slate-200 border-slate-700/50 h-[400px] mb-12"
                style={{ height: '400px' }}
              />
            </div>
          </div>

          <div>
            <label className={labelClassName}>
              Featured Image
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              required
              className={`
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-emerald-50 file:text-emerald-700
                hover:file:bg-emerald-100
                text-slate-400
                w-full
                cursor-pointer
              `}
            />
            {uploadProgress > 0 && uploadProgress < 100 && (
              <div className="mt-2">
                <div className="w-full bg-slate-700 rounded-full h-2.5">
                  <div 
                    className="bg-emerald-500 h-2.5 rounded-full transition-all duration-300"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
                <p className="text-sm text-slate-400 mt-1">
                  Uploading: {Math.round(uploadProgress)}%
                </p>
              </div>
            )}
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => navigate('/dashboard')}
              className="px-6 py-2.5 rounded-lg border border-slate-700/50 text-slate-300 hover:bg-slate-800/50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className={`
                px-6 py-2.5 
                bg-emerald-600 
                text-white 
                rounded-lg 
                hover:bg-emerald-700 
                disabled:opacity-50 
                disabled:cursor-not-allowed
                transition-colors
                duration-200
              `}
            >
              {isLoading ? (
                <div className="flex items-center gap-2">
                  <div className="w-5 h-5 border-t-2 border-r-2 border-white rounded-full animate-spin"></div>
                  <span>Creating...</span>
                </div>
              ) : (
                'Create Article'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewArticle;
