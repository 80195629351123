import { toast } from 'react-hot-toast';

// Environment check
const isDevelopment = process.env.NODE_ENV === 'development';

type LogLevel = 'info' | 'warn' | 'error';

interface LogOptions {
  showToast?: boolean;
  sensitiveKeys?: string[];
}

// Sanitize data by removing sensitive information
const sanitizeData = (data: any, sensitiveKeys: string[] = []): any => {
  const defaultSensitiveKeys = [
    'password',
    'token',
    'email',
    'phone',
    'address',
    'photoURL',
    'uid',
    'enrollmentNumber',
    'aadhaarNumber',
    'dateOfBirth',
    'bloodGroup',
    'currentState',
    'currentDistrict',
    'currentCityVillage',
    'currentPinCode',
    'fatherOrHusbandName'
  ];

  const allSensitiveKeys = Array.from(new Set(defaultSensitiveKeys.concat(sensitiveKeys)));

  if (typeof data !== 'object' || data === null) {
    return data;
  }

  if (Array.isArray(data)) {
    return data.map(item => sanitizeData(item, allSensitiveKeys));
  }

  const sanitized: any = {};
  Object.keys(data).forEach(key => {
    if (allSensitiveKeys.includes(key.toLowerCase())) {
      sanitized[key] = '[REDACTED]';
    } else if (typeof data[key] === 'object' && data[key] !== null) {
      sanitized[key] = sanitizeData(data[key], allSensitiveKeys);
    } else {
      sanitized[key] = data[key];
    }
  });

  return sanitized;
};

class Logger {
  private static instance: Logger;
  private readonly toastDuration = 3000;

  private constructor() {}

  static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  private log(level: LogLevel, message: string, data?: any, options: LogOptions = {}) {
    const { showToast = false, sensitiveKeys = [] } = options;

    // In production, only show user-facing messages via toast
    if (!isDevelopment) {
      if (showToast) {
        switch (level) {
          case 'error':
            toast.error(message, { duration: this.toastDuration });
            break;
          case 'warn':
            toast(message, { 
              duration: this.toastDuration,
              icon: '⚠️'
            });
            break;
          case 'info':
            toast.success(message, { duration: this.toastDuration });
            break;
        }
      }
      return;
    }

    // In development, we can log to console but sanitize the data
    const sanitizedData = data ? sanitizeData(data, sensitiveKeys) : undefined;
    const logMessage = sanitizedData ? `${message} ${JSON.stringify(sanitizedData, null, 2)}` : message;

    switch (level) {
      case 'error':
        console.error(logMessage);
        if (showToast) toast.error(message, { duration: this.toastDuration });
        break;
      case 'warn':
        console.warn(logMessage);
        if (showToast) {
          toast(message, { 
            duration: this.toastDuration,
            icon: '⚠️'
          });
        }
        break;
      case 'info':
        console.info(logMessage);
        if (showToast) toast.success(message, { duration: this.toastDuration });
        break;
    }
  }

  info(message: string, data?: any, options: LogOptions = {}) {
    this.log('info', message, data, options);
  }

  warn(message: string, data?: any, options: LogOptions = {}) {
    this.log('warn', message, data, options);
  }

  error(message: string, data?: any, options: LogOptions = {}) {
    this.log('error', message, data, options);
  }
}

export const logger = Logger.getInstance();
