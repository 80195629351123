import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../../config/firebase';
import { useAuth } from '../../../contexts/AuthContext'; // Fixing AuthContext import
import { FiSave, FiImage, FiArrowLeft } from 'react-icons/fi';
import { logger } from '../../../utils/logger';

interface Article {
  id?: string;
  heading: string;
  subHeading?: string;
  content: string;
  featuredImage?: string;
  authorId: string;
  authorName?: string;
  status: 'draft' | 'published';
  createdAt?: any;
  updatedAt?: any;
}

const EditArticle = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [article, setArticle] = useState<Article>({
    heading: '',
    content: '',
    authorId: currentUser?.uid || '', // Initializing article with current user
    authorName: currentUser?.displayName || '', // Initializing article with current user
    status: 'draft'
  });

  useEffect(() => {
    const fetchArticle = async () => {
      if (!id || !currentUser) {
        logger.error('No article ID provided or no authenticated user found', null, { showToast: true });
        navigate('/dashboard/articles');
        return;
      }

      setLoading(true);
      try {
        const docRef = doc(db, 'articles', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          // Check if the current user is the author
          if (data.authorId !== currentUser.uid) {
            logger.error('You do not have permission to edit this article', null, { showToast: true });
            navigate('/dashboard/articles');
            return;
          }
          
          setArticle({
            heading: data.heading || '',
            subHeading: data.subHeading || '',
            content: data.content || '',
            featuredImage: data.featuredImage || '',
            authorId: data.authorId || currentUser.uid,
            authorName: data.authorName || currentUser.displayName || '',
            status: data.status || 'draft'
          });
          logger.info('Article fetched successfully');
        } else {
          logger.error('Article not found', null, { showToast: true });
          navigate('/dashboard/articles');
        }
      } catch (error) {
        logger.error('Error fetching article', null, { showToast: true });
        navigate('/dashboard/articles');
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id, currentUser, navigate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setArticle(prev => ({ ...prev, [name]: value }));
  };

  const handleImageUpload = async (file: File) => {
    try {
      const imageRef = ref(storage, `articles/${currentUser?.uid}/${Date.now()}-${file.name}`);
      const snapshot = await uploadBytes(imageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      setArticle(prev => ({ ...prev, featuredImage: url }));
      logger.info('Image uploaded successfully', null, { showToast: true });
    } catch (error) {
      logger.error('Error uploading image', null, { showToast: true });
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      handleImageUpload(e.target.files[0]);
    }
  };

  const handleBack = () => {
    navigate('/dashboard/articles');
  };

  const handleSave = async () => {
    if (!id) {
      logger.error('No article ID found', null, { showToast: true });
      return;
    }

    setSaving(true);
    try {
      const articleData = {
        heading: article.heading,
        subHeading: article.subHeading,
        content: article.content,
        featuredImage: article.featuredImage,
        authorId: currentUser?.uid || '',
        authorName: currentUser?.displayName || '',
        updatedAt: serverTimestamp()
      };

      await updateDoc(doc(db, 'articles', id), articleData);
      logger.info('Article updated successfully', null, { showToast: true });
      navigate('/dashboard/articles');
    } catch (error) {
      logger.error('Error updating article', null, { showToast: true });
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="min-h-screen bg-gray-900"
    >
      {/* Header */}
      <div className="sticky top-0 z-10 bg-gray-900/80 backdrop-blur-xl border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-4 flex items-center justify-between">
            <button
              onClick={handleBack}
              className="inline-flex items-center px-4 py-2 bg-gray-800/50 text-white rounded-xl hover:bg-gray-800 transition-colors"
            >
              <FiArrowLeft className="w-5 h-5 mr-2" />
              Back
            </button>
            <button
              onClick={handleSave}
              disabled={saving}
              className="inline-flex items-center px-6 py-2 bg-primary hover:bg-primary/90 text-white rounded-xl transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FiSave className="w-5 h-5 mr-2" />
              {saving ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Left Column - Main Content */}
          <div className="lg:col-span-2 space-y-6">
            <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/5 overflow-hidden">
              <div className="p-6">
                <div className="space-y-6">
                  <input
                    type="text"
                    name="heading"
                    value={article.heading}
                    onChange={handleInputChange}
                    className="w-full bg-gray-900/50 border border-white/10 rounded-xl px-4 py-3 text-xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                    placeholder="Article Title"
                  />
                  <input
                    type="text"
                    name="subHeading"
                    value={article.subHeading || ''}
                    onChange={handleInputChange}
                    className="w-full bg-gray-900/50 border border-white/10 rounded-xl px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                    placeholder="Subtitle (Optional)"
                  />
                </div>
              </div>
            </div>

            <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/5 overflow-hidden">
              <div className="p-6">
                <textarea
                  name="content"
                  value={article.content}
                  onChange={handleInputChange}
                  className="w-full h-[500px] bg-gray-900/50 border border-white/10 rounded-xl px-4 py-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50 resize-none"
                  placeholder="Write your article content here..."
                />
              </div>
            </div>
          </div>

          {/* Right Column - Featured Image */}
          <div className="lg:col-span-1">
            <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-white/5 sticky top-24">
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center gap-2 text-lg font-semibold text-white">
                    <FiImage className="w-5 h-5" />
                    <span>Featured Image</span>
                  </div>
                  {article.featuredImage && (
                    <button
                      onClick={() => setArticle(prev => ({ ...prev, featuredImage: '' }))}
                      className="px-2 py-1 text-xs text-red-400 hover:text-red-300 transition-colors"
                    >
                      Remove
                    </button>
                  )}
                </div>
                <div className="space-y-4">
                  <div className="flex gap-2">
                    <input
                      type="text"
                      name="featuredImage"
                      value={article.featuredImage || ''}
                      onChange={handleInputChange}
                      className="flex-1 bg-gray-900/50 border border-white/10 rounded-xl px-4 py-2.5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary/50"
                      placeholder="Enter image URL"
                    />
                    <button
                      onClick={() => document.getElementById('imageUpload')?.click()}
                      className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded-xl transition-colors"
                    >
                      Browse
                    </button>
                    <input
                      id="imageUpload"
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                  </div>
                  {article.featuredImage ? (
                    <div className="relative group">
                      <div className="aspect-video rounded-lg overflow-hidden bg-gray-900/50">
                        <img
                          src={article.featuredImage}
                          alt="Featured"
                          className="w-full h-full object-cover"
                          onError={() => setArticle(prev => ({ ...prev, featuredImage: '' }))}
                        />
                      </div>
                      <div className="absolute inset-0 bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                        <button
                          onClick={() => document.getElementById('imageUpload')?.click()}
                          className="px-4 py-2 bg-white/10 hover:bg-white/20 text-white rounded-lg backdrop-blur-sm transition-colors"
                        >
                          Change Image
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div 
                      onClick={() => document.getElementById('imageUpload')?.click()}
                      className="aspect-video rounded-lg bg-gray-900/50 flex flex-col items-center justify-center text-gray-400 cursor-pointer hover:bg-gray-900/70 transition-colors"
                    >
                      <FiImage className="w-8 h-8 mb-2" />
                      <span>Click to add image</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default EditArticle;
