import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, where, doc, setDoc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject, UploadTaskSnapshot } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import { UserData, UserHistory } from '../../../types/user';
import { getAuth, onAuthStateChanged, User as FirebaseUser } from 'firebase/auth';
import { logger } from '../../../utils/logger';

const UserDetails: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [userData, setUserData] = useState<UserData>({
    fullName: '',
    email: '',
    phone: '',
    aadhaarNumber: '',
    memberType: 'National',
    memberStatus: 'Active',
    designation: '',
    officeState: '',
    officeDistrict: '',
    officeCityVillage: '',
    enrollmentNumber: '',
    range: '',
    rank: '',
    validFrom: new Date().toISOString().split('T')[0],
    currentAddressLine1: '',
    currentAddressLine2: '',
    currentState: '',
    currentDistrict: '',
    currentCityVillage: '',
    currentPinCode: '',
    currentStdCode: '',
    permanentAddressLine1: '',
    permanentAddressLine2: '',
    permanentState: '',
    permanentDistrict: '',
    permanentCityVillage: '',
    permanentPinCode: '',
    permanentStdCode: '',
    sameAsCurrent: false,
    witness1Name: '',
    witness1Phone: '',
    witness1Aadhaar: '',
    witness2Name: '',
    witness2Phone: '',
    witness2Aadhaar: '',
    history: [],
    photo: '',
    aadhaarFront: '',
    aadhaarBack: '',
    vehicleRegistration: '',
    promissoryNote: '',
    gender: '',
    dateOfBirth: '',
    bloodGroup: '',
    fatherOrHusbandName: '',
    education: '',
    languagesKnown: '',
    identificationMark: '',
    profession: '',
    postHeld: '',
    vehicleMake: '',
    vehicleModel: '',
    profileUpdated: false
  });
  const [uploadProgress, setUploadProgress] = useState<{
    [key: string]: { status: 'idle' | 'uploading' | 'completed' | 'error'; progress: number }
  }>({
    photo: { status: 'idle', progress: 0 },
    aadhaarFront: { status: 'idle', progress: 0 },
    aadhaarBack: { status: 'idle', progress: 0 },
    vehicleRegistration: { status: 'idle', progress: 0 },
    promissoryNote: { status: 'idle', progress: 0 }
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [previewUrls, setPreviewUrls] = useState<Record<string, string>>({});
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [compressing, setCompressing] = useState(false);

  const getInputValue = (key: keyof UserData): string => {
    const value = userData[key];
    if (value instanceof Date) {
      return value.toISOString().split('T')[0];
    }
    if (typeof value === 'boolean') {
      return value.toString();
    }
    return value?.toString() || '';
  };

  const validatePincode = (pincode: string) => {
    // Allow empty or numbers up to 6 digits
    return pincode === '' || /^[0-9]{0,6}$/.test(pincode);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    // For pin codes, only allow numbers and limit to 6 digits
    if (name === 'currentPinCode' || name === 'permanentPinCode') {
      const newValue = value.replace(/\D/g, '').slice(0, 6);
      if (!validatePincode(newValue)) {
        return;
      }
      e.target.value = newValue;
    }

    setUserData(prev => {
      const newData = { ...prev, [name]: e.target.value };
      
      // If same as current is checked, update permanent address fields when current address fields change
      if (prev.sameAsCurrent && name.startsWith('current')) {
        const permanentField = 'permanent' + name.slice(7);
        newData[permanentField] = e.target.value;
      }
      
      return newData;
    });
  };

  const handleSameAsCurrentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setUserData(prev => ({
      ...prev,
      sameAsCurrent: checked,
      permanentAddressLine1: checked ? prev.currentAddressLine1 : '',
      permanentAddressLine2: checked ? prev.currentAddressLine2 : '',
      permanentState: checked ? prev.currentState : '',
      permanentDistrict: checked ? prev.currentDistrict : '',
      permanentCityVillage: checked ? prev.currentCityVillage : '',
      permanentPinCode: checked ? prev.currentPinCode : '',
      permanentStdCode: checked ? prev.currentStdCode : ''
    }));
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        logger.error('You must be logged in to upload files', null, { showToast: true });
        return;
      }

      // Initialize progress
      setUploadProgress(prev => ({
        ...prev,
        [fieldName]: { status: 'uploading', progress: 0 }
      }));

      // Compress image if it's an image file
      let fileToUpload: File | Blob = file;
      if (file.type.startsWith('image/')) {
        try {
          setCompressing(true);
          fileToUpload = await compressImage(file);
          setCompressing(false);
        } catch (error) {
          logger.error('Error compressing image:', error, { showToast: true });
          fileToUpload = file;
          setCompressing(false);
        }
      }

      const storage = getStorage();
      const fileRef = ref(storage, `users/${user.uid}/${fieldName}/${Date.now()}_${file.name}`);
      const uploadTask = uploadBytesResumable(fileRef, fileToUpload);

      uploadTask.on(
        'state_changed',
        (snapshot: UploadTaskSnapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(prev => ({
            ...prev,
            [fieldName]: { status: 'uploading', progress }
          }));
        },
        (error: Error) => {
          logger.error(`Error uploading ${fieldName}:`, error, { showToast: true });
          setUploadProgress(prev => ({
            ...prev,
            [fieldName]: { status: 'error', progress: 0 }
          }));
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setUserData(prev => ({
              ...prev,
              [fieldName]: downloadURL
            }));
            setUploadProgress(prev => ({
              ...prev,
              [fieldName]: { status: 'completed', progress: 100 }
            }));
            logger.info(`${fieldName} uploaded successfully`, null, { showToast: true });
          } catch (error) {
            logger.error(`Error getting download URL for ${fieldName}:`, error, { showToast: true });
            setUploadProgress(prev => ({
              ...prev,
              [fieldName]: { status: 'error', progress: 0 }
            }));
          }
        }
      );
    } catch (error) {
      logger.error(`Error in ${fieldName} upload:`, error, { showToast: true });
      setUploadProgress(prev => ({
        ...prev,
        [fieldName]: { status: 'error', progress: 0 }
      }));
    }
  };

  const handleRemoveFile = async (fieldName: string) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        logger.error('You must be logged in to remove files', null, { showToast: true });
        return;
      }

      // Only attempt to delete from storage if we have a valid URL
      if (hasValidFileUrl(fieldName)) {
        const storage = getStorage();
        try {
          // Create a reference to the file to delete
          const fileRef = ref(storage, userData[fieldName]);
          await deleteObject(fileRef);
        } catch (error) {
          logger.error(`Error deleting file from storage: ${error}`, null, { showToast: true });
          // Continue with updating the userData even if storage delete fails
        }
      }

      // Update the user data to remove the file URL
      setUserData(prev => ({
        ...prev,
        [fieldName]: ''
      }));

      // Reset the upload progress
      setUploadProgress(prev => ({
        ...prev,
        [fieldName]: { status: 'idle', progress: 0 }
      }));

      logger.info(`${fieldName} removed successfully`, null, { showToast: true });
    } catch (error) {
      logger.error(`Error removing ${fieldName}:`, error, { showToast: true });
    }
  };

  const compressImage = async (file: File): Promise<File> => {
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: file.type as 'image/jpeg' | 'image/png',
      initialQuality: 0.9,
    };

    let compressedBlob = await imageCompression(file, options);
    
    let compressedFile = new File(
      [compressedBlob],
      file.name,
      {
        type: compressedBlob.type,
        lastModified: file.lastModified
      }
    );
    
    if (compressedFile.size > file.size) {
      compressedFile = file;
    }

    if (compressedFile.size > 2 * 1024 * 1024) {
      const progressiveOptions = {
        ...options,
        initialQuality: 0.8,
        maxSizeMB: 1.9
      };
      compressedBlob = await imageCompression(file, progressiveOptions);
      compressedFile = new File(
        [compressedBlob],
        file.name,
        {
          type: compressedBlob.type,
          lastModified: file.lastModified
        }
      );
    }

    return compressedFile;
  };

  const createHistoryEntry = (existingData: UserData | null, newData: UserData, user: { email: string }): UserHistory => {
    const changes: { [key: string]: { old: any; new: any } } = {};
    
    if (existingData) {
      Object.keys(newData).forEach(key => {
        // Skip certain fields from history tracking
        if (['history', 'createdAt', 'createdBy', 'updatedAt', 'lastUpdatedBy'].includes(key)) {
          return;
        }
        
        if (JSON.stringify(existingData[key]) !== JSON.stringify(newData[key])) {
          changes[key] = {
            old: existingData[key] || '',
            new: newData[key] || ''
          };
        }
      });
    } else {
      Object.keys(newData).forEach(key => {
        // Skip certain fields from history tracking
        if (['history', 'createdAt', 'createdBy', 'updatedAt', 'lastUpdatedBy'].includes(key)) {
          return;
        }
        
        changes[key] = {
          old: '',
          new: newData[key] || ''
        };
      });
    }

    return {
      timestamp: new Date().toISOString(),
      changes,
      updatedBy: user.email || 'system'
    };
  };

  const saveUserChanges = async (newData: UserData, existingData: UserData | null) => {
    // Create history entry
    const auth = getAuth();
    const user = auth.currentUser;
    
    const historyEntry = createHistoryEntry(existingData, newData, { 
      email: user?.email || 'system' 
    });

    // Clean the data before updating
    const cleanData = (data: any): any => {
      const cleaned: any = {};
      for (const [key, value] of Object.entries(data)) {
        // Convert undefined or null to empty string
        if (value === undefined || value === null) {
          cleaned[key] = '';
        }
        // Convert Date objects to ISO string
        else if (value instanceof Date) {
          cleaned[key] = value.toISOString();
        }
        // Handle arrays (like history)
        else if (Array.isArray(value)) {
          cleaned[key] = value.map(item => 
            typeof item === 'object' ? cleanData(item) : (item || '')
          );
        }
        // Handle nested objects
        else if (typeof value === 'object' && value !== null) {
          cleaned[key] = cleanData(value);
        }
        // Handle all other values
        else {
          cleaned[key] = value;
        }
      }
      return cleaned;
    };

    // Update the user data with new timestamps and history
    const updatedData = cleanData({
      ...newData,
      updatedAt: new Date().toISOString(),
      lastUpdatedBy: user?.email || 'system',
      history: [...(newData.history || []), historyEntry]
    });

    // If this is a new user, add creation info
    if (!existingData) {
      updatedData.createdAt = new Date().toISOString();
      updatedData.createdBy = user?.email || 'system';
    }

    return { updatedData, historyEntry };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        logger.error('You must be logged in to update details', null, { showToast: true });
        return;
      }

      // Get user document from Firestore
      const db = getFirestore();
      const userDetailsRef = collection(db, 'userDetails');
      const q = query(userDetailsRef, where('authEmail', '==', user.email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        logger.error('User details not found', null, { showToast: true });
        return;
      }

      const userDoc = querySnapshot.docs[0];
      const validityDate = new Date();
      validityDate.setFullYear(validityDate.getFullYear() + 1);

      // Prepare initial data with all required fields
      const initialData: UserData = {
        ...userData,
        validUntil: validityDate.toISOString().split('T')[0],
        validFrom: userData.validFrom || new Date().toISOString().split('T')[0],
        updatedAt: new Date().toISOString(),
        profileUpdated: true,
        history: userData.history || [],
        // Ensure all required fields have default values
        photo: userData.photo || '',
        aadhaarFront: userData.aadhaarFront || '',
        aadhaarBack: userData.aadhaarBack || '',
        vehicleRegistration: userData.vehicleRegistration || '',
        promissoryNote: userData.promissoryNote || '',
        memberType: userData.memberType || 'National',
        memberStatus: userData.memberStatus || 'Active',
        designation: userData.designation || '',
        gender: userData.gender || '',
        dateOfBirth: userData.dateOfBirth || '',
        bloodGroup: userData.bloodGroup || '',
        education: userData.education || '',
        languagesKnown: userData.languagesKnown || '',
        identificationMark: userData.identificationMark || '',
        profession: userData.profession || '',
        postHeld: userData.postHeld || '',
        vehicleMake: userData.vehicleMake || '',
        vehicleModel: userData.vehicleModel || ''
      };

      // Create history entry and get cleaned data
      const { updatedData } = await saveUserChanges(initialData, userDoc.data() as UserData);

      // Update the existing document
      await setDoc(doc(db, 'userDetails', userDoc.id), updatedData);

      logger.info('User details updated successfully', null, { showToast: true });
      navigate('/dashboard');
    } catch (error) {
      logger.error('Error updating user details:', error, { showToast: true });
    } finally {
      setIsLoading(false);
    }
  };



  const inputClassName = `
    w-full px-4 py-3 
    bg-slate-800/40 
    border border-slate-700/50 
    rounded-lg 
    focus:ring-2 focus:ring-emerald-500/50 focus:border-emerald-500/50 
    transition-all duration-200
    text-slate-200
    [-webkit-text-fill-color:rgb(226,232,240)]
    [&::placeholder]:[-webkit-text-fill-color:rgb(100,116,139)]
    [&::-webkit-input-placeholder]:[-webkit-text-fill-color:rgb(100,116,139)]
    [&:-webkit-autofill]:[background-color:transparent]
    [&:-webkit-autofill]:[box-shadow:0_0_0_1000px_transparent_inset]
    [&:-webkit-autofill]:[-webkit-text-fill-color:rgb(226,232,240)]
    [&:-webkit-autofill]:[transition:background-color_5000s_ease-in-out_0s]
  `;

  const labelClassName = `
    block text-sm font-medium 
    text-slate-400 
    mb-1.5
  `;

  const requiredLabelClassName = `
    block text-sm font-medium 
    text-slate-400 
    mb-1.5
    after:content-['*'] 
    after:ml-1 
    after:text-red-500
  `;

  const sectionClassName = `
    bg-slate-900/50 
    backdrop-blur-sm 
    rounded-xl 
    border border-slate-800/50 
    p-6 md:p-8 
    space-y-6
    shadow-lg 
    shadow-slate-900/20
    transition-all duration-300 
    hover:shadow-slate-900/30
  `;

  const sectionHeaderClassName = `
    text-xl font-semibold 
    text-emerald-400
    mb-6 
    flex items-center gap-2
    border-b border-slate-800/50 
    pb-4
  `;

  const gridClassName = `
    grid grid-cols-1 
    md:grid-cols-2 
    lg:grid-cols-4 
    gap-6
  `;

  const buttonClassName = `
    px-6 py-2.5 
    rounded-lg 
    font-medium 
    transition-all duration-200 
    focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-900
  `;

  const hasValidFileUrl = (fieldName: string) => {
    return userData[fieldName] && typeof userData[fieldName] === 'string' && userData[fieldName].length > 0;
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          setIsFetching(true);
          const db = getFirestore();
          const userDetailsRef = collection(db, 'userDetails');
          const q = query(userDetailsRef, where('authEmail', '==', user.email));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userDocData = userDoc.data();
            setUserData(prevData => ({
              ...prevData,
              ...userDocData,
              fullName: userDocData.fullName || '',
              email: userDocData.email || '',
              designation: userDocData.designation || ''
            }));
          }
        } catch (error) {
          logger.error('Error fetching user details:', error, { showToast: true });
        } finally {
          setIsFetching(false);
        }
      } else {
        setIsFetching(false);
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const checkProfileStatus = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        navigate('/login');
        return;
      }

      try {
        const db = getFirestore();
        const userDoc = await getDoc(doc(db, 'userDetails', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // If profile is already updated, redirect to dashboard
          if (userData.profileUpdated) {
            navigate('/dashboard');
          }
        }
      } catch (error) {
        logger.error('Error checking profile status:', error, { showToast: true });
      }
    };

    checkProfileStatus();
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-slate-900 to-slate-800 text-slate-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-3xl font-bold text-emerald-400">Update Details</h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-8">
          {/* Personal Details */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              Personal Details
            </h2>
            <div className={gridClassName}>
              <div>
                <label className={requiredLabelClassName}>Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  value={getInputValue('fullName')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter full name"
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>Email</label>
                <input
                  type="email"
                  name="email"
                  value={getInputValue('email')}
                  className={inputClassName}
                  placeholder="Email will be copied from Authentication Details"
                  disabled
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  value={getInputValue('phone')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter phone number"
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>Gender</label>
                <select
                  name="gender"
                  value={getInputValue('gender')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                >
                  <option value="" className="bg-gray-700">Select Gender</option>
                  <option value="male" className="bg-gray-700">Male</option>
                  <option value="female" className="bg-gray-700">Female</option>
                  <option value="other" className="bg-gray-700">Other</option>
                </select>
              </div>
              <div>
                <label className={requiredLabelClassName}>Date of Birth</label>
                <input
                  type="date"
                  name="dateOfBirth"
                  value={getInputValue('dateOfBirth')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>Aadhaar Number</label>
                <input
                  type="text"
                  name="aadhaarNumber"
                  value={getInputValue('aadhaarNumber')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter Aadhaar number"
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>Blood Group</label>
                <select
                  name="bloodGroup"
                  value={getInputValue('bloodGroup')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  required
                >
                  <option value="" className="bg-gray-700">Select Blood Group</option>
                  <option value="A+" className="bg-gray-700">A+</option>
                  <option value="A-" className="bg-gray-700">A-</option>
                  <option value="B+" className="bg-gray-700">B+</option>
                  <option value="B-" className="bg-gray-700">B-</option>
                  <option value="AB+" className="bg-gray-700">AB+</option>
                  <option value="AB-" className="bg-gray-700">AB-</option>
                  <option value="O+" className="bg-gray-700">O+</option>
                  <option value="O-" className="bg-gray-700">O-</option>
                </select>
              </div>
              <div>
                <label className={requiredLabelClassName}>Father/Husband Name</label>
                <input
                  type="text"
                  name="fatherOrHusbandName"
                  value={getInputValue('fatherOrHusbandName')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter father's/husband's name"
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>Education</label>
                <input
                  type="text"
                  name="education"
                  value={getInputValue('education')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter education details"
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>Languages Known</label>
                <input
                  type="text"
                  name="languagesKnown"
                  value={getInputValue('languagesKnown')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter languages known (comma separated)"
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>Identification Mark</label>
                <input
                  type="text"
                  name="identificationMark"
                  value={getInputValue('identificationMark')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter identification mark"
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>Profession</label>
                <input
                  type="text"
                  name="profession"
                  value={getInputValue('profession')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter profession"
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>Post Held</label>
                <input
                  type="text"
                  name="postHeld"
                  value={getInputValue('postHeld')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter post held"
                  required
                />
              </div>
            </div>
          </div>

          {/* Current Address */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              Current Address
            </h2>
            <div className={gridClassName}>
              <div>
                <label className={requiredLabelClassName}>Address Line 1</label>
                <input
                  type="text"
                  name="currentAddressLine1"
                  value={getInputValue('currentAddressLine1')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter address line 1"
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>Address Line 2</label>
                <input
                  type="text"
                  name="currentAddressLine2"
                  value={getInputValue('currentAddressLine2')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter address line 2"
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>State</label>
                <input
                  type="text"
                  name="currentState"
                  value={getInputValue('currentState')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter state"
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>District</label>
                <input
                  type="text"
                  name="currentDistrict"
                  value={getInputValue('currentDistrict')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter district"
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>City/Village</label>
                <input
                  type="text"
                  name="currentCityVillage"
                  value={getInputValue('currentCityVillage')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter city/village"
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>Pin Code</label>
                <input
                  type="text"
                  name="currentPinCode"
                  value={getInputValue('currentPinCode')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter PIN code"
                  required
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>STD Code</label>
                <input
                  type="text"
                  name="currentStdCode"
                  value={getInputValue('currentStdCode')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter STD code"
                  required
                />
              </div>
            </div>
          </div>

          {/* Permanent Address */}
          <div className={sectionClassName}>
            <div className="flex items-center justify-between mb-6">
              <h2 className={sectionHeaderClassName}>
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5m3-3h1" />
                </svg>
                Permanent Address
              </h2>
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="sameAsCurrent"
                  name="sameAsCurrent"
                  checked={userData.sameAsCurrent}
                  onChange={handleSameAsCurrentChange}
                  className="w-4 h-4 rounded border-gray-500/30 bg-gray-800/50 
                   text-blue-500 focus:ring-blue-500/30 focus:ring-offset-0
                   transition-all duration-300"
                />
                <label htmlFor="sameAsCurrent" className="text-sm text-gray-300 hover:text-blue-400 transition-colors duration-300">
                  Same as Current Address
                </label>
              </div>
            </div>
            <div className={gridClassName}>
              <div>
                <label className={requiredLabelClassName}>Address Line 1</label>
                <input
                  type="text"
                  name="permanentAddressLine1"
                  value={getInputValue('permanentAddressLine1')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter address line 1"
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>
              <div>
                <label className={labelClassName}>Address Line 2</label>
                <input
                  type="text"
                  name="permanentAddressLine2"
                  value={getInputValue('permanentAddressLine2')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter address line 2"
                  disabled={userData.sameAsCurrent}
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>State</label>
                <input
                  type="text"
                  name="permanentState"
                  value={getInputValue('permanentState')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter state"
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>District</label>
                <input
                  type="text"
                  name="permanentDistrict"
                  value={getInputValue('permanentDistrict')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter district"
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>City/Village</label>
                <input
                  type="text"
                  name="permanentCityVillage"
                  value={getInputValue('permanentCityVillage')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter city/village"
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>Pin Code</label>
                <input
                  type="text"
                  name="permanentPinCode"
                  value={getInputValue('permanentPinCode')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter PIN code"
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>
              <div>
                <label className={requiredLabelClassName}>STD Code</label>
                <input
                  type="text"
                  name="permanentStdCode"
                  value={getInputValue('permanentStdCode')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter STD code"
                  required
                  disabled={userData.sameAsCurrent}
                />
              </div>
            </div>
          </div>

          {/* Vehicle Details */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 16v3a2 2 0 01-2 2H7a2 2 0 01-2-2v-3m14 0V7a2 2 0 00-2-2H7a2 2 0 00-2 2v9m14 0h-3m-9 0H3m2 0h3m3-3h1" />
              </svg>
              Vehicle Details
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <label className={labelClassName}>Make</label>
                <input
                  type="text"
                  name="vehicleMake"
                  value={getInputValue('vehicleMake')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter vehicle make"
                />
              </div>
              <div>
                <label className={labelClassName}>Model</label>
                <input
                  type="text"
                  name="vehicleModel"
                  value={getInputValue('vehicleModel')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter vehicle model"
                />
              </div>
              <div>
                <label className={labelClassName}>Registration Number</label>
                <input
                  type="text"
                  name="vehicleRegistration"
                  value={getInputValue('vehicleRegistration')}
                  onChange={handleInputChange}
                  className={inputClassName}
                  placeholder="Enter registration number"
                />
              </div>
            </div>
          </div>

          {/* Witness Details */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
              </svg>
              Witness Details
            </h2>
            
            {/* Witness 1 */}
            <div className="mb-6">
              <h3 className="text-lg font-medium mb-4 text-white border-b border-white/10 pb-2">Witness 1</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={requiredLabelClassName}>Full Name</label>
                  <input
                    type="text"
                    name="witness1Name"
                    value={getInputValue('witness1Name')}
                    onChange={handleInputChange}
                    className={inputClassName}
                    placeholder="Enter witness full name"
                    required
                  />
                </div>
                <div>
                  <label className={requiredLabelClassName}>Phone Number</label>
                  <input
                    type="tel"
                    name="witness1Phone"
                    value={getInputValue('witness1Phone')}
                    onChange={handleInputChange}
                    className={inputClassName}
                    placeholder="Enter phone number"
                    required
                  />
                </div>
                <div>
                  <label className={requiredLabelClassName}>Aadhaar Number</label>
                  <input
                    type="text"
                    name="witness1Aadhaar"
                    value={getInputValue('witness1Aadhaar')}
                    onChange={handleInputChange}
                    className={inputClassName}
                    placeholder="Enter Aadhaar number"
                    required
                  />
                </div>
              </div>
            </div>

            {/* Witness 2 */}
            <div>
              <h3 className="text-lg font-medium mb-4 text-white border-b border-white/10 pb-2">Witness 2</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div>
                  <label className={requiredLabelClassName}>Full Name</label>
                  <input
                    type="text"
                    name="witness2Name"
                    value={getInputValue('witness2Name')}
                    onChange={handleInputChange}
                    className={inputClassName}
                    placeholder="Enter witness full name"
                    required
                  />
                </div>
                <div>
                  <label className={requiredLabelClassName}>Phone Number</label>
                  <input
                    type="tel"
                    name="witness2Phone"
                    value={getInputValue('witness2Phone')}
                    onChange={handleInputChange}
                    className={inputClassName}
                    placeholder="Enter phone number"
                    required
                  />
                </div>
                <div>
                  <label className={requiredLabelClassName}>Aadhaar Number</label>
                  <input
                    type="text"
                    name="witness2Aadhaar"
                    value={getInputValue('witness2Aadhaar')}
                    onChange={handleInputChange}
                    className={inputClassName}
                    placeholder="Enter Aadhaar number"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Document Uploads */}
          <div className={sectionClassName}>
            <h2 className={sectionHeaderClassName}>
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              Document Uploads
            </h2>
            <div className={gridClassName}>
              {/* Photo Upload */}
              <div>
                <label className={requiredLabelClassName}>Photo</label>
                <div className="flex items-center space-x-4">
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={(e) => handleFileChange(e, 'photo')}
                    className={`
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-full file:border-0
                      file:text-sm file:font-semibold
                      file:bg-emerald-50 file:text-emerald-700
                      hover:file:bg-emerald-100
                      text-slate-400
                      w-full
                      cursor-pointer
                    `}
                    required
                  />
                  {uploadProgress.photo?.status === 'uploading' && (
                    <div className="h-1 w-full bg-gray-700 rounded">
                      <div
                        className="h-1 bg-emerald-500 rounded transition-all duration-300"
                        style={{ width: `${uploadProgress.photo.progress}%` }}
                      />
                    </div>
                  )}
                  {hasValidFileUrl('photo') && (
                    <button
                      type="button"
                      onClick={() => handleRemoveFile('photo')}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  )}
                </div>
                {hasValidFileUrl('photo') && (
                  <a
                    href={userData.photo}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-emerald-500 hover:text-emerald-400 text-sm mt-1 inline-block"
                  >
                    View uploaded photo
                  </a>
                )}
              </div>

              {/* Aadhaar Front Upload */}
              <div>
                <label className={requiredLabelClassName}>Aadhaar Front</label>
                <div className="flex items-center space-x-4">
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={(e) => handleFileChange(e, 'aadhaarFront')}
                    className={`
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-full file:border-0
                      file:text-sm file:font-semibold
                      file:bg-emerald-50 file:text-emerald-700
                      hover:file:bg-emerald-100
                      text-slate-400
                      w-full
                      cursor-pointer
                    `}
                    required
                  />
                  {uploadProgress.aadhaarFront?.status === 'uploading' && (
                    <div className="h-1 w-full bg-gray-700 rounded">
                      <div
                        className="h-1 bg-emerald-500 rounded transition-all duration-300"
                        style={{ width: `${uploadProgress.aadhaarFront.progress}%` }}
                      />
                    </div>
                  )}
                  {hasValidFileUrl('aadhaarFront') && (
                    <button
                      type="button"
                      onClick={() => handleRemoveFile('aadhaarFront')}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  )}
                </div>
                {hasValidFileUrl('aadhaarFront') && (
                  <a
                    href={userData.aadhaarFront}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-emerald-500 hover:text-emerald-400 text-sm mt-1 inline-block"
                  >
                    View uploaded Aadhaar front
                  </a>
                )}
              </div>

              {/* Aadhaar Back Upload */}
              <div>
                <label className={requiredLabelClassName}>Aadhaar Back</label>
                <div className="flex items-center space-x-4">
                  <input
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    onChange={(e) => handleFileChange(e, 'aadhaarBack')}
                    className={`
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-full file:border-0
                      file:text-sm file:font-semibold
                      file:bg-emerald-50 file:text-emerald-700
                      hover:file:bg-emerald-100
                      text-slate-400
                      w-full
                      cursor-pointer
                    `}
                    required
                  />
                  {uploadProgress.aadhaarBack?.status === 'uploading' && (
                    <div className="h-1 w-full bg-gray-700 rounded">
                      <div
                        className="h-1 bg-emerald-500 rounded transition-all duration-300"
                        style={{ width: `${uploadProgress.aadhaarBack.progress}%` }}
                      />
                    </div>
                  )}
                  {hasValidFileUrl('aadhaarBack') && (
                    <button
                      type="button"
                      onClick={() => handleRemoveFile('aadhaarBack')}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  )}
                </div>
                {hasValidFileUrl('aadhaarBack') && (
                  <a
                    href={userData.aadhaarBack}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-emerald-500 hover:text-emerald-400 text-sm mt-1 inline-block"
                  >
                    View uploaded Aadhaar back
                  </a>
                )}
              </div>

              {/* Vehicle Registration Upload */}
              <div>
                <label className={labelClassName}>Vehicle Registration</label>
                <div className="flex items-center space-x-4">
                  <input
                    type="file"
                    accept=".pdf,.jpg,.jpeg,.png"
                    onChange={(e) => handleFileChange(e, 'vehicleRegistration')}
                    className={`
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-full file:border-0
                      file:text-sm file:font-semibold
                      file:bg-emerald-50 file:text-emerald-700
                      hover:file:bg-emerald-100
                      text-slate-400
                      w-full
                      cursor-pointer
                    `}
                  />
                  {uploadProgress.vehicleRegistration?.status === 'uploading' && (
                    <div className="h-1 w-full bg-gray-700 rounded">
                      <div
                        className="h-1 bg-emerald-500 rounded transition-all duration-300"
                        style={{ width: `${uploadProgress.vehicleRegistration.progress}%` }}
                      />
                    </div>
                  )}
                  {hasValidFileUrl('vehicleRegistration') && (
                    <button
                      type="button"
                      onClick={() => handleRemoveFile('vehicleRegistration')}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  )}
                </div>
                {hasValidFileUrl('vehicleRegistration') && (
                  <a
                    href={userData.vehicleRegistration}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-emerald-500 hover:text-emerald-400 text-sm mt-1 inline-block"
                  >
                    View uploaded vehicle registration
                  </a>
                )}
              </div>

              {/* Promissory Note Upload */}
              <div>
                <label className={requiredLabelClassName}>Promissory Note</label>
                <div className="flex items-center space-x-4">
                  <input
                    type="file"
                    accept=".pdf,.jpg,.jpeg,.png"
                    onChange={(e) => handleFileChange(e, 'promissoryNote')}
                    className={`
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-full file:border-0
                      file:text-sm file:font-semibold
                      file:bg-emerald-50 file:text-emerald-700
                      hover:file:bg-emerald-100
                      text-slate-400
                      w-full
                      cursor-pointer
                    `}
                    required
                  />
                  {uploadProgress.promissoryNote?.status === 'uploading' && (
                    <div className="h-1 w-full bg-gray-700 rounded">
                      <div
                        className="h-1 bg-emerald-500 rounded transition-all duration-300"
                        style={{ width: `${uploadProgress.promissoryNote.progress}%` }}
                      />
                    </div>
                  )}
                  {hasValidFileUrl('promissoryNote') && (
                    <button
                      type="button"
                      onClick={() => handleRemoveFile('promissoryNote')}
                      className="text-red-500 hover:text-red-700"
                    >
                      Remove
                    </button>
                  )}
                </div>
                {hasValidFileUrl('promissoryNote') && (
                  <a
                    href={userData.promissoryNote}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-emerald-500 hover:text-emerald-400 text-sm mt-1 inline-block"
                  >
                    View uploaded promissory note
                  </a>
                )}
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="sticky bottom-0 bg-slate-900/90 backdrop-blur-sm border-t border-slate-800 py-4 z-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex justify-end gap-4">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`${buttonClassName} ${
                    isLoading
                      ? 'bg-emerald-600/50 cursor-not-allowed'
                      : 'bg-emerald-600 hover:bg-emerald-700'
                  } text-white focus:ring-emerald-500`}
                >
                  {isLoading ? (
                    <div className="flex items-center gap-2">
                      <div className="w-5 h-5 border-t-2 border-r-2 border-white rounded-full animate-spin"></div>
                      <span>Updating...</span>
                    </div>
                  ) : (
                    'Update Details'
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserDetails;
